import cloneDeep from 'lodash/cloneDeep'



export const logInUser = (obj) => {
  // console.log('obj = ', obj)
  window.localStorage.setItem('id', obj.id)
  window.localStorage.setItem('login', obj.login)
  window.localStorage.setItem('avatar', obj.avatar)
  window.localStorage.setItem('role', obj.role)

  return {
  type: 'LOG_USER',
  payload: { ...obj }
}}



export const logOutUser = () => {
  window.localStorage.removeItem('id')
  window.localStorage.removeItem('login')
  window.localStorage.removeItem('avatar')
  window.localStorage.removeItem('role')

  return {
    type: 'LOG_OUT_USER'
  }
}



export const addToCart = (obj) => {
  window.localStorage.setItem( 'cart', JSON.stringify(obj.cart) )

  return {
    type: 'ADD_TO_CART',
    payload: { ...cloneDeep(obj) }
  }
}



export const setCountry = (country) => {
  window.localStorage.setItem( 'country', country )

  return {
    type: 'SET_COUNTRY',
    payload: { country }
  }
}



export const setLogin = (login) => {
  window.localStorage.setItem('login', login)

  return {
    type: 'SET_LOGIN',
    payload: { login }
  }
}



export const setNumberOfNewNotifications = (numberOfNewNotifications) => {
  return {
    type: 'SET_NUMBER_OF_NEW_NOTIFICATIONS',
    payload: { numberOfNewNotifications }
  }
}



let isFetching = false
export const getNewNotifications = () => {
  return (dispatch, getState) => {
    // console.log('dispatch = ', dispatch)
    // console.log('getState() = ', getState())
    if (isFetching) {
      return
    }

    isFetching = true
    window.tempFetch(`/api/v1/headerLogged/?getNumberOfNewNotifications`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(r => {
        if (!r.ok) {
          throw new Error(`${r.status} ${r.statusText}`)
        }
        return r.json()
      })
      .then(json => {
        // console.log('json = ', json)
        if (json.err) {
          console.log('json.err = ', json.err)
        } else if (json.success) {
          // console.log('here we are')
          let { numberOfNewNotifications } = json
          dispatch(setNumberOfNewNotifications(numberOfNewNotifications))
        } else {
          console.log('Something is wrong')
        }
      })
      .catch(err => {
        console.log('err = ', err)
      })
      .finally(() => {
        isFetching = false
      })
  }
}










const receiveToken = json => {
  const { token, expiresIn } = json
  window.localStorage.setItem('token', token)
  window.localStorage.setItem('expiresIn', +new Date() + expiresIn * 1000)
  return {
    type: 'RECEIVE_TOKEN',
    payload: { token, expiresIn }
  }
}



export const refreshToken = token => {
  return dispatch => {
    fetch(`/api/v1/token/extend/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(r => {
        if (!r.ok) {
          return Promise.reject('Anuathorize')
        } else {
          return r.json()
        }
      })
      .then(json => {
        dispatch(receiveToken(json))
      })
      .catch(err => {
        console.log('token = ', token);
        console.log('refreshToken err = ', err);
      })
  }
}







export const updateLogin = login => ({
  type: 'UPDATE_LOGIN',
  payload: { login }
})



export const updateAvatar = avatar => {
  window.localStorage.setItem('avatar', avatar)
  
  return {
    type: 'UPDATE_AVATAR',
    payload: { avatar }
  }
}



// message redirect "Live message"
export const setMessageRedirect = (messageRedirectId) => ({
  type: 'SET_MESSAGE_REDIRECT',
  payload: { messageRedirectId }
})

export const removeMessageRedirect = () => ({
  type: 'REMOVE_MESSAGE_REDIRECT'
})



// publish form
export const setProjectForm = (title, description, specializations, budget, deadline, language) => ({
  type: 'SET_PROJECT_FORM',
  payload: { title, description, specializations, budget, deadline, language }
})

export const removeProjectForm = () => ({
  type: 'REMOVE_PROJECT_FORM'
})



// project form
export const setOfferForm = (toId, description, budget, deadline, projectId) => ({
  type: 'SET_OFFER_FORM',
  payload: { toId, description, budget, deadline, projectId }
})

export const removeOfferForm = () => ({
  type: 'REMOVE_OFFER_FORM'
})



// publish form
export const setOrderForm = (title, description, budget, deadline, freelancerId) => ({
  type: 'SET_ORDER_FORM',
  payload: { title, description, budget, deadline, freelancerId }
})

export const removeOrderForm = () => ({
  type: 'REMOVE_ORDER_FORM'
})



// changeViewEditProfile
const changeViewEditProfileByValue = (viewEditProfile) => {
  window.sessionStorage.setItem('viewEditProfile', viewEditProfile)
  
  return {
    type: 'CHANGE_VIEW_EDIT_PROFILE',
    payload: { viewEditProfile }
  } 
}

export const changeViewEditProfile = () => {
  return (dispatch, getState) => {
    const state = getState()
    let { viewEditProfile } = state.globals
    // console.log('viewEditProfile = ', viewEditProfile)
    viewEditProfile = viewEditProfile === 'Edit' ? 'View' : 'Edit'
    // console.log('viewEditProfile = ', viewEditProfile)
    dispatch(changeViewEditProfileByValue(viewEditProfile))
  }
}
