import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'

import store from './redux/store'
import App from './App';



const rootEle = document.getElementById('root')

if (rootEle.hasChildNodes()) {
  // console.log('hydrate')
  ReactDOM.hydrate(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>,
  rootEle)
} else {
  // console.log('render')
  ReactDOM.render(
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>, 
    rootEle)
}
