import React from 'react'
import moment from 'moment'
import 'moment-precise-range-plugin'
import { Link } from 'react-router-dom'



// getNameOfPath - return the name of the path



// get google captcha locale
export const getReCaptchaLocale = (locale) => {
  if (locale === 'ua') {
    return 'uk'
  } else if (locale === 'in') {
    return 'hi'
  } else if (locale === 'cn') {
    return 'zh-CN'
  } else {
    return locale
  }
}



// insert ReCaptcha
export const insertReCaptcha = (locale) => {
  if (!window.isLoadingReCaptcha) {
    window.isLoadingReCaptcha = true;

    // include recaptcha files
    window.onloadCallback = () => {
      window.grecaptchaLoaded = true
      // console.log('2window.grecaptcha = ', window.grecaptcha)
    }

    // 1. include a recaptcha API
    let head = document.getElementsByTagName('head')[0]
    let script = document.createElement('script')
    script.setAttribute('data-main', 'js/main');
    script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=${getReCaptchaLocale(locale)}`
    script.type = "text/javascript"
    script.async = true 
    script.defer = true
    script.id = 'my-script'

    // console.log('script = ', script)
    head.appendChild(script)
  }
}



// delete reCaptcha
export const deleteReCaptcha = () => {
  document.querySelectorAll('head script[src*=recaptcha]').forEach(e => e.parentNode.removeChild(e))
}



// return the name of the path
// @path /uploads/2021/01/28/2/b977653a-5add-47a8-a35b-4a3b8d5c71b5_imgFile.jpg
// return: b977653a-5add-47a8-a35b-4a3b8d5c71b5_imgFile.jpg
export const getNameOfPath = function (path) {
    return path.split('\\').pop().split('/').pop();
}






export const getDurationOnTheSite = (d, locale) => {
  if (locale === 'en') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)
    if (diff.years) {
      let years = diff.years
      if (years === 1) {
        years = `${years} year`
      } else {
        years = `${years} years`
      }

      let months = diff.months
      if (months === 1) {
        months = `${months} month`
      } else {
        months = `${months} months`
      }

      return `${years} ${months}`
    } else if (diff.months) {
      let months = diff.months
      if (months === 1) {
        months = `${months} month`
      } else {
        months = `${months} months`
      }

      let days = diff.days
      if (days === 1) {
        days = `${days} day`
      } else {
        days = `${days} days`
      }

      return `${months} ${days}`
    } else if (diff.days) {
      let days = diff.days
      if (days === 1) {
        days = `${days} day`
      } else {
        days = `${days} days`
      }

      let hours = diff.hours
      if (hours === 1) {
        hours = `${hours} hour`
      } else {
        hours = `${hours} hours`
      }

      return `${days} ${hours}`
    } else if (diff.hours) {
      let hours = diff.hours
      if (hours === 1) {
        hours = `${hours} hour`
      } else {
        hours = `${hours} hours`
      }

      let minutes = diff.minutes
      if (minutes === 1) {
        minutes = `${minutes} minute`
      } else {
        minutes = `${minutes} minutes`
      }

      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      let minutes = diff.minutes
      if (minutes === 1) {
        minutes = `${minutes} minute`
      } else {
        minutes = `${minutes} minutes`
      }

      let seconds = diff.seconds
      if (seconds === 1) {
        seconds = `${seconds} second`
      } else {
        seconds = `${seconds} seconds`
      }

      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      let seconds = diff.seconds
      if (seconds === 1) {
        seconds = `${seconds} second`
      } else {
        seconds = `${seconds} seconds`
      }

      return `${diff.seconds}`
    }
  } else if (locale === 'ru') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)
    if (diff.years) {
      // 6. years
      let years = diff.years
      if (years === 0) {
        years = `${years} лет`
      } else if (years === 1) {
        years = `${years} год`
      } else if (years >= 2 && years <= 4) {
        years = `${years} года`
      } else if (years >= 5 && years <= 20) {
        years = `${years} лет`
      } else if (years > 20) {
        let ten = Math.round(years % 10)
        if (ten === 0) {
          years = `${years} лет`
        } else if (ten === 1) {
          years = `${years} год`
        } else if (ten >= 2 && ten <= 4) {
          years = `${years} года`
        } else if (ten >= 5 && ten <= 10) {
          years = `${years} лет`
        }
      }

      // 5. months
      let months = diff.months
      if (months === 0) {
        months = `${months} месяцeв`
      } else if (months === 1) {
        months = `${months} месяц`
      } else if (months >= 2 && months <= 4) {
        months = `${months} месяца`
      } else if (months >= 5 && months <= 20) {
        months = `${months} месяцeв`
      } else if (months > 20) {
        let ten = Math.round(months % 10)
        if (ten === 0) {
          months = `${months} месяцeв`
        } else if (ten === 1) {
          months = `${months} месяц`
        } else if (ten >= 2 && ten <= 4) {
          months = `${months} месяца`
        } else if (ten >= 5 && ten <= 10) {
          months = `${months} месяцeв`
        }
      }

      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      if (months === 0) {
        months = `${months} месяцeв`
      } else if (months === 1) {
        months = `${months} месяц`
      } else if (months >= 2 && months <= 4) {
        months = `${months} месяца`
      } else if (months >= 5 && months <= 20) {
        months = `${months} месяцeв`
      } else if (months > 20) {
        let ten = Math.round(months % 10)
        if (ten === 0) {
          months = `${months} месяцeв`
        } else if (ten === 1) {
          months = `${months} месяц`
        } else if (ten >= 2 && ten <= 4) {
          months = `${months} месяца`
        } else if (ten >= 5 && ten <= 10) {
          months = `${months} месяцeв`
        }
      }

      // 4. days
      let days = diff.days
      if (days === 0) {
        days = `${days} дней`
      } else if (days === 1) {
        days = `${days} день`
      } else if (days >= 2 && days <= 4) {
        days = `${days} дня`
      } else if (days >= 5 && days <= 20) {
        days = `${days} дней`
      } else if (days > 20) {
        let ten = Math.round(days % 10)
        if (ten === 0) {
          days = `${days} дней`
        } else if (ten === 1) {
          days = `${days} день`
        } else if (ten >= 2 && ten <= 4) {
          days = `${days} дня`
        } else if (ten >= 5 && ten <= 10) {
          days = `${days} дней`
        }
      }

      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      if (days === 0) {
        days = `${days} дней`
      } else if (days === 1) {
        days = `${days} день`
      } else if (days >= 2 && days <= 4) {
        days = `${days} дня`
      } else if (days >= 5 && days <= 20) {
        days = `${days} дней`
      } else if (days > 20) {
        let ten = Math.round(days % 10)
        if (ten === 0) {
          days = `${days} дней`
        } else if (ten === 1) {
          days = `${days} день`
        } else if (ten >= 2 && ten <= 4) {
          days = `${days} дня`
        } else if (ten >= 5 && ten <= 10) {
          days = `${days} дней`
        }
      }

      // 3. hours
      let hours = diff.hours
      if (hours === 0) {
        hours = `${hours} часов`
      } else if (hours === 1) {
        hours = `${hours} час`
      } else if (hours >= 2 && hours <= 4) {
        hours = `${hours} часа`
      } else if (hours >= 5 && hours <= 20) {
        hours = `${hours} часов`
      } else if (hours > 20) {
        let ten = Math.round(hours % 10)
        if (ten === 0) {
          hours = `${hours} часов`
        } else if (ten === 1) {
          hours = `${hours} час`
        } else if (ten >= 2 && ten <= 4) {
          hours = `${hours} часа`
        } else if (ten >= 5 && ten <= 10) {
          hours = `${hours} часов`
        }
      }

      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      if (hours === 0) {
        hours = `${hours} часов`
      } else if (hours === 1) {
        hours = `${hours} час`
      } else if (hours >= 2 && hours <= 4) {
        hours = `${hours} часа`
      } else if (hours >= 5 && hours <= 20) {
        hours = `${hours} часов`
      } else if (hours > 20) {
        let ten = Math.round(hours % 10)
        if (ten === 0) {
          hours = `${hours} часов`
        } else if (ten === 1) {
          hours = `${hours} час`
        } else if (ten >= 2 && ten <= 4) {
          hours = `${hours} часа`
        } else if (ten >= 5 && ten <= 10) {
          hours = `${hours} часов`
        }
      }

      // 2. minutes
      let minutes = diff.minutes
      if (minutes === 0) {
        minutes = `${minutes} минут`
      } else if (minutes === 1) {
        minutes = `${minutes} минуту`
      } else if (minutes >= 2 && minutes <= 4) {
        minutes = `${minutes} минуты`
      } else if (minutes >= 5 && minutes <= 20) {
        minutes = `${minutes} минут`
      } else if (minutes > 20) {
        let ten = Math.round(minutes % 10)
        if (ten === 0) {
          minutes = `${minutes} минут`
        } else if (ten === 1) {
          minutes = `${minutes} минуту`
        } else if (ten >= 2 && ten <= 4) {
          minutes = `${minutes} минуты`
        } else if (ten >= 5 && ten <= 10) {
          minutes = `${minutes} минут`
        }
      }
      
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      if (minutes === 0) {
        minutes = `${minutes} минут`
      } else if (minutes === 1) {
        minutes = `${minutes} минуту`
      } else if (minutes >= 2 && minutes <= 4) {
        minutes = `${minutes} минуты`
      } else if (minutes >= 5 && minutes <= 20) {
        minutes = `${minutes} минут`
      } else if (minutes > 20) {
        let ten = Math.round(minutes % 10)
        if (ten === 0) {
          minutes = `${minutes} минут`
        } else if (ten === 1) {
          minutes = `${minutes} минуту`
        } else if (ten >= 2 && ten <= 4) {
          minutes = `${minutes} минуты`
        } else if (ten >= 5 && ten <= 10) {
          minutes = `${minutes} минут`
        }
      }

      // 1. seconds
      let seconds = diff.seconds
      if (seconds === 0) {
        seconds = `${seconds} секунд`
      } else if (seconds === 1) {
        seconds = `${seconds} секунду`
      } else if (seconds >= 2 && seconds <= 4) {
        seconds = `${seconds} секунды`
      } else if (seconds >= 5 && seconds <= 20) {
        seconds = `${seconds} секунд`
      } else if (seconds > 20) {
        let ten = Math.round(seconds % 10)
        if (ten === 0) {
          seconds = `${seconds} секунд`
        } else if (ten === 1) {
          seconds = `${seconds} секунду`
        } else if (ten >= 2 && ten <= 4) {
          seconds = `${seconds} секунды`
        } else if (ten >= 5 && ten <= 10) {
          seconds = `${seconds} секунд`
        }
      }
      
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      if (seconds === 0) {
        seconds = `${seconds} секунд`
      } else if (seconds === 1) {
        seconds = `${seconds} секунду`
      } else if (seconds >= 2 && seconds <= 4) {
        seconds = `${seconds} секунды`
      } else if (seconds >= 5 && seconds <= 20) {
        seconds = `${seconds} секунд`
      } else if (seconds > 20) {
        let ten = Math.round(seconds % 10)
        if (ten === 0) {
          seconds = `${seconds} секунд`
        } else if (ten === 1) {
          seconds = `${seconds} секунду`
        } else if (ten >= 2 && ten <= 4) {
          seconds = `${seconds} секунды`
        } else if (ten >= 5 && ten <= 10) {
          seconds = `${seconds} секунд`
        }
      }
      
      return `${seconds}`
    }
  } else if (locale === 'ua') {
    const date = moment(d)
    const now = moment()
    const diff = moment.preciseDiff(now, date, true)
    if (diff.years) {
      // 6. years
      let years = diff.years
      if (years === 0) {
        years = `${years} років`
      } else if (years === 1) {
        years = `${years} рік`
      } else if (years >= 2 && years <= 4) {
        years = `${years} роки`
      } else if (years >= 5 && years <= 20) {
        years = `${years} років`
      } else if (years > 20) {
        let ten = Math.round(years % 10)
        if (ten === 0) {
          years = `${years} років`
        } else if (ten === 1) {
          years = `${years} рік`
        } else if (ten >= 2 && ten <= 4) {
          years = `${years} роки`
        } else if (ten >= 5 && ten <= 10) {
          years = `${years} років`
        }
      }

      // 5. months
      let months = diff.months
      if (months === 0) {
        months = `${months} місяців`
      } else if (months === 1) {
        months = `${months} місяць`
      } else if (months >= 2 && months <= 4) {
        months = `${months} місяці`
      } else if (months >= 5 && months <= 20) {
        months = `${months} місяців`
      } else if (months > 20) {
        let ten = Math.round(months % 10)
        if (ten === 0) {
          months = `${months} місяців`
        } else if (ten === 1) {
          months = `${months} місяць`
        } else if (ten >= 2 && ten <= 4) {
          months = `${months} місяці`
        } else if (ten >= 5 && ten <= 10) {
          months = `${months} місяців`
        }
      }

      return `${years} ${months}`
    } else if (diff.months) {
      // 5. months
      let months = diff.months
      if (months === 0) {
        months = `${months} місяців`
      } else if (months === 1) {
        months = `${months} місяць`
      } else if (months >= 2 && months <= 4) {
        months = `${months} місяці`
      } else if (months >= 5 && months <= 20) {
        months = `${months} місяців`
      } else if (months > 20) {
        let ten = Math.round(months % 10)
        if (ten === 0) {
          months = `${months} місяців`
        } else if (ten === 1) {
          months = `${months} місяць`
        } else if (ten >= 2 && ten <= 4) {
          months = `${months} місяці`
        } else if (ten >= 5 && ten <= 10) {
          months = `${months} місяців`
        }
      }

      // 4. days
      let days = diff.days
      if (days === 0) {
        days = `${days} днів`
      } else if (days === 1) {
        days = `${days} день`
      } else if (days >= 2 && days <= 4) {
        days = `${days} дні`
      } else if (days >= 5 && days <= 20) {
        days = `${days} днів`
      } else if (days > 20) {
        let ten = Math.round(days % 10)
        if (ten === 0) {
          days = `${days} днів`
        } else if (ten === 1) {
          days = `${days} день`
        } else if (ten >= 2 && ten <= 4) {
          days = `${days} дні`
        } else if (ten >= 5 && ten <= 10) {
          days = `${days} днів`
        }
      }

      return `${months} ${days}`
    } else if (diff.days) {
      // 4. days
      let days = diff.days
      if (days === 0) {
        days = `${days} днів`
      } else if (days === 1) {
        days = `${days} день`
      } else if (days >= 2 && days <= 4) {
        days = `${days} дні`
      } else if (days >= 5 && days <= 20) {
        days = `${days} днів`
      } else if (days > 20) {
        let ten = Math.round(days % 10)
        if (ten === 0) {
          days = `${days} днів`
        } else if (ten === 1) {
          days = `${days} день`
        } else if (ten >= 2 && ten <= 4) {
          days = `${days} дні`
        } else if (ten >= 5 && ten <= 10) {
          days = `${days} днів`
        }
      }

      // 3. hours
      let hours = diff.hours
      if (hours === 0) {
        hours = `${hours} годин`
      } else if (hours === 1) {
        hours = `${hours} годину`
      } else if (hours >= 2 && hours <= 4) {
        hours = `${hours} години`
      } else if (hours >= 5 && hours <= 20) {
        hours = `${hours} годин`
      } else if (hours > 20) {
        let ten = Math.round(hours % 10)
        if (ten === 0) {
          hours = `${hours} годин`
        } else if (ten === 1) {
          hours = `${hours} годину`
        } else if (ten >= 2 && ten <= 4) {
          hours = `${hours} години`
        } else if (ten >= 5 && ten <= 10) {
          hours = `${hours} годин`
        }
      }

      return `${days} ${hours}`
    } else if (diff.hours) {
      // 3. hours
      let hours = diff.hours
      if (hours === 0) {
        hours = `${hours} годин`
      } else if (hours === 1) {
        hours = `${hours} годину`
      } else if (hours >= 2 && hours <= 4) {
        hours = `${hours} години`
      } else if (hours >= 5 && hours <= 20) {
        hours = `${hours} годин`
      } else if (hours > 20) {
        let ten = Math.round(hours % 10)
        if (ten === 0) {
          hours = `${hours} годин`
        } else if (ten === 1) {
          hours = `${hours} годину`
        } else if (ten >= 2 && ten <= 4) {
          hours = `${hours} години`
        } else if (ten >= 5 && ten <= 10) {
          hours = `${hours} годин`
        }
      }

      // 2. minutes
      let minutes = diff.minutes
      if (minutes === 0) {
        minutes = `${minutes} хвилин`
      } else if (minutes === 1) {
        minutes = `${minutes} хвилину`
      } else if (minutes >= 2 && minutes <= 4) {
        minutes = `${minutes} хвилини`
      } else if (minutes >= 5 && minutes <= 20) {
        minutes = `${minutes} хвилин`
      } else if (minutes > 20) {
        let ten = Math.round(minutes % 10)
        if (ten === 0) {
          minutes = `${minutes} хвилин`
        } else if (ten === 1) {
          minutes = `${minutes} хвилину`
        } else if (ten >= 2 && ten <= 4) {
          minutes = `${minutes} хвилини`
        } else if (ten >= 5 && ten <= 10) {
          minutes = `${minutes} хвилин`
        }
      }
      
      return `${hours} ${minutes}`
    } else if (diff.minutes) {
      // 2. minutes
      let minutes = diff.minutes
      if (minutes === 0) {
        minutes = `${minutes} хвилин`
      } else if (minutes === 1) {
        minutes = `${minutes} хвилину`
      } else if (minutes >= 2 && minutes <= 4) {
        minutes = `${minutes} хвилини`
      } else if (minutes >= 5 && minutes <= 20) {
        minutes = `${minutes} хвилин`
      } else if (minutes > 20) {
        let ten = Math.round(minutes % 10)
        if (ten === 0) {
          minutes = `${minutes} хвилин`
        } else if (ten === 1) {
          minutes = `${minutes} хвилину`
        } else if (ten >= 2 && ten <= 4) {
          minutes = `${minutes} хвилини`
        } else if (ten >= 5 && ten <= 10) {
          minutes = `${minutes} хвилин`
        }
      }

      // 1. seconds
      let seconds = diff.seconds
      if (seconds === 0) {
        seconds = `${seconds} секунд`
      } else if (seconds === 1) {
        seconds = `${seconds} секунду`
      } else if (seconds >= 2 && seconds <= 4) {
        seconds = `${seconds} секунды`
      } else if (seconds >= 5 && seconds <= 20) {
        seconds = `${seconds} секунд`
      } else if (seconds > 20) {
        let ten = Math.round(seconds % 10)
        if (ten === 0) {
          seconds = `${seconds} секунд`
        } else if (ten === 1) {
          seconds = `${seconds} секунду`
        } else if (ten >= 2 && ten <= 4) {
          seconds = `${seconds} секунды`
        } else if (ten >= 5 && ten <= 10) {
          seconds = `${seconds} секунд`
        }
      }
      
      return `${minutes} ${seconds}`
    } else if (diff.seconds) {
      // 1. seconds
      let seconds = diff.seconds
      if (seconds === 0) {
        seconds = `${seconds} секунд`
      } else if (seconds === 1) {
        seconds = `${seconds} секунду`
      } else if (seconds >= 2 && seconds <= 4) {
        seconds = `${seconds} секунды`
      } else if (seconds >= 5 && seconds <= 20) {
        seconds = `${seconds} секунд`
      } else if (seconds > 20) {
        let ten = Math.round(seconds % 10)
        if (ten === 0) {
          seconds = `${seconds} секунд`
        } else if (ten === 1) {
          seconds = `${seconds} секунду`
        } else if (ten >= 2 && ten <= 4) {
          seconds = `${seconds} секунды`
        } else if (ten >= 5 && ten <= 10) {
          seconds = `${seconds} секунд`
        }
      }
      
      return `${seconds}`
    }
  }

  // console.log('locale = ', locale)
}


export const getPreciseDiff = (str, locale) => {
  // console.log('str = ', str)
  // console.log('locale = ', locale)
  if (locale === 'en') {
    ;
  } else if (locale === 'ru') {
    str = str.replace(/hours/g, "часы")
    str = str.replace(/minutes/g, "минут")
    str = str.replace(/seconds/g, "секунд")
  } else if (locale === 'ua') {
    str = str.replace(/hours/g, "години")
    str = str.replace(/minutes/g, "хвилин")
    str = str.replace(/seconds/g, "секунд")
  } else if (locale === 'in') {
    str = str.replace(/hours/g, "घंटे")
    str = str.replace(/minutes/g, "मिनट")
    str = str.replace(/seconds/g, "सेकंड")
  } else if (locale === 'cn') {
    str = str.replace(/hours/g, "小时")
    str = str.replace(/minutes/g, "分钟")
    str = str.replace(/seconds/g, "秒")
  } else if (locale === 'es') {
    str = str.replace(/hours/g, "horas")
    str = str.replace(/minutes/g, "minutos")
    str = str.replace(/seconds/g, "segundos")
  } else if (locale === 'de') {
    str = str.replace(/hours/g, "Std")
    str = str.replace(/minutes/g, "Protokoll")
    str = str.replace(/seconds/g, "Sekunden")
  } else if (locale === 'pl') {
    str = str.replace(/hours/g, "godziny")
    str = str.replace(/minutes/g, "minuty")
    str = str.replace(/seconds/g, "sekundy")
  } else if (locale === 'fr') {
    str = str.replace(/hours/g, "heures")
    str = str.replace(/minutes/g, "minutes")
    str = str.replace(/seconds/g, "seconds")
  }

  return str
}






export const getAgreement = (locale) => {
  let phrase = ''
  if (locale === 'en') {
    phrase = <div className="agreement">By clicking the “Register” button, I agree with <Link to={`/${locale}/siterules/`}>the site rules</Link>, I agree to the processing of personal data.</div>
  } else if (locale === 'ru') {
    phrase = <div className="agreement">Нажимая кнопку «Зарегистрироваться», я соглашаюсь c <Link to={`/${locale}/siterules/`}>правилами сайта</Link>, я согласен на обработку персональных данных.</div>
  } else if (locale === 'ua') {
    phrase = <div className="agreement">Натиснувши кнопку «Зареєструватися», я погоджуюся з <Link to={`/${locale}/siterules/`}>правилами сайту</Link>, я погоджуюся на обробку персональних даних.</div>
  }
  return phrase
}







export const getReviews = (numberOfReviews, locale) => {
  let phrase = ''
  if (locale === 'ru') {
    let ten = Math.round(numberOfReviews % 10)
    if (ten === 0) {
      phrase = `${numberOfReviews} отзывов`
    } else if (ten === 1) {
      phrase = `${numberOfReviews} отзыв`
    } else if (ten >= 2 && ten <= 4) {
      phrase = `${numberOfReviews} отзыва`
    } else if (ten >= 5 && ten <= 10) {
      phrase = `${numberOfReviews} отзывов`
    }



  } else if (locale === 'ua') {
    let ten = Math.round(numberOfReviews % 10)
    if (ten === 0) {
      phrase = `${numberOfReviews} відгуків`
    } else if (ten === 1) {
      phrase = `${numberOfReviews} відгук`
    } else if (ten >= 2 && ten <= 4) {
      phrase = `${numberOfReviews} відгуки`
    } else if (ten >= 5 && ten <= 10) {
      phrase = `${numberOfReviews} відгуків`
    }



  } else {
    if (numberOfReviews == 0) {
      phrase = `${numberOfReviews} review`
    } else if (numberOfReviews == 1) {
      phrase = `${numberOfReviews} review`
    } else {
      phrase = `${numberOfReviews} reviews`
    }
  }

  return phrase
}






export const getViewReplies = (numberOfReviews, locale) => {
  let phrase = ''
  if (locale === 'ru') {
    let ten = Math.round(numberOfReviews % 10)
    if (ten === 0) {
      phrase = `Посмотреть ${numberOfReviews} ответов`
    } else if (ten === 1) {
      phrase = `Посмотреть ${numberOfReviews} ответ`
    } else if (ten >= 2 && ten <= 4) {
      phrase = `Посмотреть ${numberOfReviews} ответа`
    } else if (ten >= 5 && ten <= 10) {
      phrase = `Посмотреть ${numberOfReviews} ответов`
    }



  } else if (locale === 'ua') {
    let ten = Math.round(numberOfReviews % 10)
    if (ten === 0) {
      phrase = `Переглянути ${numberOfReviews} відповідей`
    } else if (ten === 1) {
      phrase = `Переглянути ${numberOfReviews} відповідь`
    } else if (ten >= 2 && ten <= 4) {
      phrase = `Переглянути ${numberOfReviews} відповіді`
    } else if (ten >= 5 && ten <= 10) {
      phrase = `Переглянути ${numberOfReviews} відповідей`
    }



  } else {
    if (numberOfReviews == 0) {
      phrase = `${numberOfReviews} reply`
    } else if (numberOfReviews == 1) {
      phrase = `${numberOfReviews} reply`
    } else {
      phrase = `${numberOfReviews} replies`
    }
  }

  return phrase
}






export const getHideReplies = (numberOfReviews, locale) => {
  let phrase = ''
  if (locale === 'ru') {
    let ten = Math.round(numberOfReviews % 10)
    if (ten === 0) {
      phrase = `Cкрыть ${numberOfReviews} ответов`
    } else if (ten === 1) {
      phrase = `Cкрыть ${numberOfReviews} ответ`
    } else if (ten >= 2 && ten <= 4) {
      phrase = `Cкрыть ${numberOfReviews} ответа`
    } else if (ten >= 5 && ten <= 10) {
      phrase = `Cкрыть ${numberOfReviews} ответов`
    }



  } else if (locale === 'ua') {
    let ten = Math.round(numberOfReviews % 10)
    if (ten === 0) {
      phrase = `Приховати ${numberOfReviews} відповідей`
    } else if (ten === 1) {
      phrase = `Приховати ${numberOfReviews} відповідь`
    } else if (ten >= 2 && ten <= 4) {
      phrase = `Приховати ${numberOfReviews} відповіді`
    } else if (ten >= 5 && ten <= 10) {
      phrase = `Приховати ${numberOfReviews} відповідей`
    }



  } else {
    if (numberOfReviews == 0) {
      phrase = `Hide ${numberOfReviews} reply`
    } else if (numberOfReviews == 1) {
      phrase = `Hide ${numberOfReviews} reply`
    } else {
      phrase = `Hide ${numberOfReviews} replies`
    }
  }

  return phrase
}






export const removeHTMLtags = (str) => {
  if (!str) {
    return ''
  }
  
  str = str.replace(/<[^<>]*?>/g, '')

  return str
}



export const escapeHtml = (text) => {
  // 1. exchange to bbcode
  let map = {
    '<b>': '[b]',
    '</b>': '[/b]',
    '<i>': '[i]',
    '</i>': '[/i]',
    '<br>': '[br]',
    '<h1>': '[h1]',
    '</h1>': '[/h1]',
    '<h2>': '[h2]',
    '</h2>': '[/h2]',
    '<h3>': '[h3]',
    '</h3>': '[/h3]',
    '<h4>': '[h4]',
    '</h4>': '[/h4]',
    '<h5>': '[h5]',
    '</h5>': '[/h5]',
    '<h6>': '[h6]',
    '</h6>': '[/h6]',
    '<p>': '[p]',
    '</p>': '[/p]',
    '<ul>': '[ul]',
    '</ul>': '[/ul]',
    '<li>': '[li]',
    '</li>': '[/li]',

  }
  text = text.replace(/(<br>|<i>|<\/i>|<b>|<\/b>|<h1>|<\/h1>|<h2>|<\/h2>|<h3>|<\/h3>|<h4>|<\/h4>|<h5>|<\/h5>|<h6>|<\/h6>|<p>|<\/p>|<ul>|<\/ul>|<li>|<\/li>)/g, function(m) { return map[m] })
  text = text.replace(/<(a[^<>]+?)>/g, '[$1]')
  text = text.replace(/<\/a>/g, '[/a]')
  // console.log('text = ', text)

  // 2. remove
  map = {
    '<': '&lt;',
    '>': '&gt;'
  }
  
  text = text.replace(/[<>]/g, function(m) { return map[m]; })

  // 3. exchange back bbcode
  map = {
    '[b]': '<b>',
    '[/b]': '</b>',
    '[i]': '<i>',
    '[/i]': '</i>',
    '[br]': '<br>',
    '[h1]': '<h1>',
    '[/h1]': '</h1>',
    '[h2]': '<h2>',
    '[/h2]': '</h2>',
    '[h3]': '<h3>',
    '[/h3]': '</h3>',
    '[h4]': '<h4>',
    '[/h4]': '</h4>',
    '[h5]': '<h5>',
    '[/h5]': '</h5>',
    '[h6]': '<h6>',
    '[/h6]': '</h6>',
    '[p]': '<p>',
    '[/p]': '</p>',
    '[ul]': '<ul>',
    '[/ul]': '</ul>',
    '[li]': '<li>',
    '[/li]': '</li>'
  }
  text = text.replace(/(\[br\]|\[i\]|\[\/i\]|\[b\]|\[\/b\]|\[h1\]|\[\/h1\]|\[h2\]|\[\/h2\]|\[h3\]|\[\/h3\]|\[h4\]|\[\/h4\]|\[h5\]|\[\/h5\]|\[h6\]|\[\/h6\]|\[p\]|\[\/p\]|\[ul\]|\[\/ul\]|\[li\]|\[\/li\])/g, function(m) { return map[m] })
  text = text.replace(/\[(a[^<>]+?)\]/g, '<$1 rel="nofollow">')
  text = text.replace(/\[\/a\]/g, '</a>')





  return text
}


export const getTagText = (text) => {
  if (text) {
    text = escapeHtml(text)
    text = text.replace(/(?<!<\/h1>|<\/h2>|<\/h3>|<\/h4>|<\/h5>|<\/h6>|<ul>|<\/ul>|<\/li>)\n/g, '<br />')
    text = text.replace(/((?<!href=")(?:https:\/\/|http:\/\/)[^\s].+)/g, '<a href="$1" rel="nofollow">$1</a>')
  }

  return text
}



export const getReview = (text) => {
  if (text) {
    text = text.replace(/\n/, '<br />')
  }
  return text
}