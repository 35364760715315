import React from 'react'
import ReactTooltip from 'react-tooltip'
import { withRouter, NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux'

import * as a from '../redux/actions'
import * as f from '../utils/functions.js'
import Config from '../Config.json'
import _isEqual from 'lodash/isEqual'
import { withStone } from '../myModules/reactStone.js'
import categoriesJSON from '../data/categories1.json'

// console.log('categoriesJSON = ', categoriesJSON)



class Header extends React.Component {
  constructor(props) {
    super(props)

    let params = new URLSearchParams(this.props.location.search)

    let keyword = '' 
    let cancelSearchShow = false
    if (!this.props.match.params.login) {
      keyword = params.get('keyword')
      if (!keyword) {
        keyword = ''
      } else {
        cancelSearchShow = true
      }
    }

    let categoryId1 = params.get('categoryId1')
    if (!categoryId1) {
      categoryId1 = 'all'
    }

    // console.log('this.props.categoryId1 = ', this.props.categoryId1)
    if (this.props.categoryId1) {
      categoryId1 = this.props.categoryId1
    }

    let country = params.get('country')
    if (!country) {
      country = 'all'
    }

    // let { categoryId1 } = this.props
    // if (!categoryId1) {
    //   categoryId1 = 'all'
    // }

    this.languageMenu = ''
    this.notificationList = ''
    this.profileMenu = ''

    this.state = {
      toggle: false,
      toggleFlags: false,
      languages: [ 'ru', 'en', 'ua' ],
      keyword,
      cancelSearchShow,
      categoryId1,
      country,

      showLangMenu: false,
      showProfileMenu: false,
      showNotificationList: false,

      notifications: [],
      startNotifications: 0,
      isFetchingNotifications: false,
      err: ''
    }
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentDidUpdate(prevProps, prevState) {
    // console.log('componentDidUpdate')
    // console.log('this.state.err.toString() = ', this.state.err.toString())
    if (this.state.err.toString() === 'Error: 401 Unauthorized') {
      window.location.href = window.location.href     
    }
    // return
    // if (this.state.categoryId1 !== this.props.categoryId1) {
    //   console.log('this.state.categoryId1 = ', this.state.categoryId1)
    //   console.log('this.props.categoryId1 = ', this.props.categoryId1)
    //   this.setState({ categoryId1: this.props.categoryId1 })
    // }
    // console.log('this.props.categoryId1 = ', this.props.categoryId1)
    // console.log('this.state.categoryId1 = ', this.state.categoryId1)
    if (this.props.categoryId1 && this.props.categoryId1 !== this.state.categoryId1) {
      this.setState({ categoryId1: this.props.categoryId1 })
      return
    }

    let params = new URLSearchParams(this.props.location.search)

    let categoryId1 = params.get('categoryId1')
    if (!categoryId1) {
      categoryId1 = 'all'
    }

    let country = params.get('country')
    if (!country) {
      country = 'all'
    }

    if (!this.props.categoryId1 && this.state.categoryId1 !== categoryId1) {
      // console.log('categoryId1')
      this.setState({ categoryId1 })
    } else if (this.state.country !== country) {
      // console.log('country')
      this.setState({ country })
    }

  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    // console.log('handleClick')
    if (
        this.state.showLangMenu &&
        !this.languageMenu.contains(e.target)
       )
    {
      // console.log('this.state.showLangMenu = ', this.state.showLangMenu)
      this.setState({ showLangMenu: false })
    }

    if (
        this.state.showNotificationList &&
        !this.notificationList.contains(e.target)
       )
    {
      this.setState({ showNotificationList: false })
    }

    if (
        this.state.showProfileMenu &&
        !this.profileMenu.contains(e.target)
       )
    {
      // console.log('this.state.showProfileMenu = ', this.state.showProfileMenu)
      this.setState({ showProfileMenu: false })
    }
  }

  onClickLanguage = locale => e => {
    // console.log('onClickLanguage ')
    e.preventDefault()
    window.location.href = `/${locale}${this.props.location.pathname.substring(3) ? this.props.location.pathname.substring(3) : '/'}${this.props.location.search}`
    // return
    // let url = `/${locale}${this.props.location.pathname.substring(3)}${this.props.location.search}`
    // // console.log('url = ', url)
    // this.props.history.push(url)
  }

  onClickLogout = e => {
    e.preventDefault()
    // console.log('onClickLogout1')
    this.isFetching = true
    // this.setState(this.state)
    fetch(`/api/v1/headerUnlogged/?logOut`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(r => {
        this.isFetching = false
        if (!r.ok) {
          return Promise.reject()
        } else {
          return r.json()
        }
      })
      .then(json => {
        // console.log('json = ', json)
        if(json.err) {
          return this.setState({ ...json })
        } else {
          let { dispatch } = this.props
          dispatch(a.logOutUser())

          this.props.history.push(`/${this.props.globals.locale}/login/`)
        }
      })
      .catch(error => {
        console.log(console.log('error = ', error))
        return this.setState({ error })
      })
  }

  onClickSearch = (e) => {
    e.preventDefault()
    // console.log('this.props = ', this.props)
    // return
    let params = new URLSearchParams(this.props.location.search)
    params.delete('startMessages')
    params.delete('keyword')
    params.append('keyword', this.state.keyword)
    params.delete('categoryId1')
    params.append('categoryId1', this.state.categoryId1)

    this.setState({ cancelSearchShow: true }, () => {
      // if (this.props.match.params.login) {
      //   this.props.history.push(`/${this.props.globals.locale}/shop/${this.props.match.params.login}/?${params.toString()}`)
      // } else {
      //   this.props.history.push(`/${this.props.globals.locale}/?${params.toString()}`)
      // }
      this.props.history.push(`/${this.props.globals.locale}/?${params.toString()}`)
    })    
  }

  render() {
    // console.log('this.props = ', this.props)
    // console.log('this.state = ', this.state)
    const _ = this.props.Stone.gettext
    let { locale, avatar, login } = this.props.globals

    const { toggle, toggleFlags } = this.state
    const active = toggle ? 'active' : ''
    // const activeFlags = toggleFlags ? 'active' : ''
    // console.log('activeFlags = ', activeFlags)

    let languages = [...this.state.languages]
    const index = languages.indexOf(locale)
    if(~index) {
      languages.splice(index, 1);
    }
    const localeFl = locale !== 'en' ? locale : 'gb'

    // console.log('this.props.globals.cart = ', this.props.globals.cart)
    let numberOfProducts = 0
    Object.values(this.props.globals.cart).forEach(e => {
      // console.log('e = ', e)
      numberOfProducts = numberOfProducts + Object.keys(e.products).length
    })
    // console.log('numberOfProducts = ', numberOfProducts)


    return (
      <div className="header">
        <div>
          <div className="block1">
            <nav className="navigation">

              <ul className="left">
                <li><NavLink to={`/${this.props.globals.locale}/help/`} exact>{_("Help")}</NavLink></li>
                <li><NavLink to={`/${this.props.globals.locale}/siterules/`} exact>{_("Site rules")}</NavLink></li>
              </ul>

              <ul className="right">

                <li className="languages" ref={input => { this.languageMenu = input }}>
                  <a
                    href="#toggle"
                    onClick={ e => {
                      e.preventDefault()
                      this.setState({ showLangMenu: !this.state.showLangMenu }) 
                    }}
                  >
                    <span className={`flag-icon flag-icon-${localeFl}`}></span>
                  </a>
                  <ul
                    className="languageMenu"
                    style={{ display: !this.state.showLangMenu ? "none" : "block" }}
                  >
                    {languages.map((locale, i) => {
                      const printLang = locale !== 'en' ? locale : 'gb'
                      return (
                        <li key={locale}>
                          <button
                            className="langButton"
                            // onClick={() => { 
                            //   this.onToggleFlags(); 
                            //   // return setLocale(locale)
                            //   return dispatch(a.setLocale(locale))
                            // } }
                            onClick={this.onClickLanguage(locale)}
                            // to={`/${locale}${this.props.location.pathname.substring(3)}`}
                          >
                            <span className={`flag-icon flag-icon-${printLang}`}></span>
                          </button>
                        </li>
                      )
                    })}
                  </ul>
                </li>



                {login &&
                  <li
                    className="notifications"
                    ref={input => { this.notificationList = input }}
                  >
                    <i
                      className="fas fa-bell"
                      onClick={() => {
                        this.setState({
                          showNotificationList: !this.state.showNotificationList,
                          notifications: [],
                          startNotifications: 0,
                          isFetchingNotifications: true
                        }, () => {

                          window.tempFetch(`/api/v1/headerLogged/?getNotifications&startNotifications=${this.state.startNotifications}`, {
                            method: 'GET',
                            headers: {
                              'Content-Type': 'application/json'
                            }
                          })
                            .then(r => {
                              if (!r.ok) {
                                throw new Error(`${r.status} ${r.statusText}`)
                              }
                              return r.json()
                            })
                            .then(json => {
                              // console.log('json = ', json)
                              if (json.err) {
                                console.log('json.err = ', json.err)
                              } else if (json.success) {
                                // console.log('here we are1')
                                let { notifications } = json

                                let startNotifications = 0

                                if (notifications.length > Config.notificationsPagination) {
                                  notifications.splice(notifications.length - 1, 1)
                                  startNotifications = this.state.startNotifications + Config.notificationsPagination
                                }

                                this.setState({ 
                                  notifications,
                                  startNotifications
                                })

                                let { dispatch } = this.props
                                dispatch(a.setNumberOfNewNotifications(0))
                              } else {
                                console.log('Something is wrong')
                              }
                            })
                            .catch(err => {
                              console.log('err = ', err)
                              this.setState({err: err})
                            })
                            .finally(() => {
                              this.setState({
                                isFetchingNotifications: false
                              })
                            })

                        })
                      }}
                    >
                      {this.props.globals.numberOfNewNotifications > 0 &&
                        <span>
                          {this.props.globals.numberOfNewNotifications}
                        </span>
                      }
                    </i>
                    <div
                      className="notificationList"
                      style={{ display: !this.state.showNotificationList ? "none" : "block" }}
                    >
                      {this.state.notifications.map(e => {

                        return (
                          <div 
                            className="item"
                            key={e.id}
                            style={{ background: e.status == 'new' ? '#12f11d' : '' }}
                            onClick={event => {
                              // console.log('redirect to the order')
                              let url = `/${this.props.globals.locale}/order/${e.orderToken}/`
                              if (!event.ctrlKey) { 
                                window.location.href = url
                              } else {
                                // console.log('ctrl key')
                                window.open(url, '_blank')
                              }
                            }}
                          >
                            <div className="order">{_("Order")}: #{e.orderId}</div>
                            <div className="total">{_("total")}: {e.total}{_(e.currency)}</div>
                            <div className="created">{f.getDurationOnTheSite(e.created, this.props.globals.locale)} {_("ago")}</div>
                          </div>
                        )
                      })}
                      {this.state.isFetchingNotifications &&
                        <div className="spiner">loading...</div>
                      }
                      {!this.state.isFetchingNotifications &&
                       this.state.startNotifications != 0 &&
                        <div
                          className="loadMore"
                          onClick={() => {
                            window.tempFetch(`/api/v1/headerLogged/?getNotifications&startNotifications=${this.state.startNotifications}`, {
                              method: 'GET',
                              header: {
                                'Content-Type': 'application/json'
                              }
                            })
                              .then(r => {
                                if (!r.ok) {
                                  throw new Error(`${r.status} ${r.statusText}`)
                                }
                                return r.json()
                              })
                              .then(json => {
                                // console.log('json = ', json)
                                if (json.err) {
                                  console.log('json.err = ', json.err)
                                } else if (json.success) {
                                  // console.log('here we are2')
                                  let { notifications } = json

                                  let startNotifications = 0

                                  if (notifications.length > Config.notificationsPagination) {
                                    notifications.splice(notifications.length - 1, 1)
                                    startNotifications = this.state.startNotifications + Config.notificationsPagination
                                  }

                                  let newNotifications = [ ...this.state.notifications, ...notifications ]

                                  this.setState({
                                    notifications: newNotifications,
                                    startNotifications
                                  })
                                } else {
                                  console.log('Something is wrong')
                                }
                              })
                              .catch(err => {
                                // console.log('err = ', err)
                                this.setState({err: err})
                              })
                          }}
                        >
                          {_("Load more")}
                        </div>
                      }
                      {!this.state.isFetchingNotifications &&
                       this.state.startNotifications == 0 && 
                       this.state.notifications.length == 0 &&
                        <div>{_("You have 0 notifications")}</div>
                      }
                    </div>
                  </li>
                }



                {login && 
                  <li
                    className="profile"
                    ref={input => { this.profileMenu = input }}
                  >
                    <img
                      onClick={() => this.setState({ showProfileMenu: !this.state.showProfileMenu })}
                      src={avatar}
                      alt=""
                    />
                    <i
                      className="fa fa-angle-down"
                      onClick={() => this.setState({ showProfileMenu: !this.state.showProfileMenu })}
                    ></i>
                    <ul
                      className="profileMenu"
                      style={{ display: !this.state.showProfileMenu ? "none" : "block" }}
                    >
                      <li><NavLink to={`/${locale}/shop/${encodeURIComponent(login)}/?edit=edit`} exact>{_("My shop")}</NavLink></li>
                      <li><NavLink to={`/${locale}/profile/${encodeURIComponent(login)}/products/`} exact>{_("Products")}</NavLink></li>
                      <li><NavLink to={`/${locale}/profile/${encodeURIComponent(login)}/add-product/`} exact>{_("Add product")}</NavLink></li>
                      <li><NavLink to={`/${locale}/profile/${encodeURIComponent(login)}/categories/`} exact>{_("Categories")}</NavLink></li>
                      <li><NavLink to={`/${locale}/profile/${encodeURIComponent(login)}/orders/`} exact>{_("Orders")}</NavLink></li>
                      <li><NavLink to={`/${locale}/profile/${encodeURIComponent(login)}/settings/`} exact>{_("Settings")}</NavLink></li>
                      <li>
                        <NavLink 
                          to={`/${locale}/logout/`}
                          onClick={this.onClickLogout}
                        >
                          {_("Log out")}
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                }
                {!login && <li><NavLink to={`/${this.props.globals.locale}/login/`} exact>{_("Log in")}</NavLink></li>}

                {!login && <li><NavLink to={`/${this.props.globals.locale}/registration/`} exact>{_("Registration")}</NavLink></li>}
              </ul>
            </nav>
          </div>










          <div className="block2">
            <div className="logo"><NavLink to={`/`} exact><img src="/img/logo.png" alt=""/></NavLink></div>

            <div className="searchField">
              <div className="search">
                <form>
                  <input
                    className="keyword"
                    type="text"
                    name="keyword"
                    // onChange={this.onChangeKeyword}
                    onChange={e => {
                      this.setState({ keyword: e.target.value })
                    }}
                    value={this.state.keyword}
                  />
                  <select
                    className="categories"
                    onChange={e => {
                      // console.log('e.target.value = ', e.target.value)
                      // this.setState({ categoryId1: e.target.value })
                      let params = new URLSearchParams(this.props.location.search)
                      params.delete('startMessages')
                      params.delete('keyword')
                      // params.append('keyword', this.state.keyword)
                      params.delete('categoryId1')
                      params.append('categoryId1', e.target.value)
                      params.delete('categoryId2')
                      params.delete('categoryId3')
                      // if (this.props.match.params.login) {
                      //   this.props.history.push(`/${this.props.globals.locale}/shop/${this.props.match.params.login}/?${params.toString()}`)
                      // } else {
                      //   this.props.history.push(`/${this.props.globals.locale}/?${params.toString()}`)
                      // }
                      this.props.history.push(`/${this.props.globals.locale}/?${params.toString()}`)
                    }}
                    value={this.state.categoryId1}
                  >
                    <option value="all">{_("All Categories")}</option>
                    {this.props.searchCategories1 && this.props.searchCategories1.map((e, index) => {
                      return (
                        <option value={e.categoryId1} key={e.categoryId1}>{_(categoriesJSON.categories1[e.categoryId1].name)}</option>
                      )
                    })}
                  </select>

                  <select
                    className="countries"
                    onChange={e => {
                      // console.log('e.target.value = ', e.target.value)
                      let params = new URLSearchParams(this.props.location.search)
                      params.delete('country')
                      params.append('country', e.target.value)
                      this.props.history.push(`/${this.props.globals.locale}/?${params.toString()}`)
                      let { dispatch } = this.props
                      dispatch( a.setCountry(e.target.value) )
                    }}
                    value={this.props.globals.country}
                  >
                    <option value="all">{_("All Countries")}</option>
                    <option value="Ukraine">{_("Ukraine")}</option>
                    <option value="Russia">{_("Russia")}</option>
                  </select>

                  <button
                    onClick={this.onClickSearch}
                  >
                    {_("Search")}
                  </button>
                  {this.state.cancelSearchShow &&
                    <div
                      className="cancelSearch"
                      onClick={e => {
                        e.preventDefault()
                        let params = new URLSearchParams(this.props.location.search)
                        params.delete('startMessages')
                        params.delete('keyword')
                        this.setState({ keyword: '', cancelSearchShow: false }, () => {
                          if (this.props.match.params.login) {
                            this.props.history.push(`/${this.props.globals.locale}/shop/${this.props.match.params.login}/?${params.toString()}`)
                          } else {
                            this.props.history.push(`/${this.props.globals.locale}/?${params.toString()}`)
                          }
                          // this.props.history.push(`/${this.props.globals.locale}/?${params.toString()}`)
                        })
                      }}
                    >
                      {_("Cancel search")}
                    </div>
                  }
                </form>
              </div>
            </div>

            <div className="shopCart">
              <NavLink to={`/${this.props.globals.locale}/shopcart/`} exact>
                <i className="fas fa-shopping-cart">
                  <span className="numberOfProducts">{numberOfProducts}</span>
                </i>
              </NavLink>
            </div>
          </div>

        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  // const { login, avatar } = state.globals
  // return { login, avatar }
  const { globals } = state
  return { globals }
}

Header = connect(mapStateToProps)(Header)
Header = withRouter(Header)
Header = withStone(Header)



export default Header