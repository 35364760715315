import React, { Suspense, lazy } from 'react'
import { Route,
         Switch,
         withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { StoneProvider } from './myModules/reactStone.js'
import * as a from './redux/actions.js'
import * as f from './utils/functions.js'
import Header from './components/Header'

// import enJson from './data/en.json'
const Home = lazy(() => import('./components/Home/Home.js'))
const NoFound = lazy(() => import('./components/NoFound'))
const Login = lazy(() => import('./components/Login'))
const Registration = lazy(() => import('./components/Registration'))
// const Shop = lazy(() => import('./components/Shop'))
const Shop = lazy(() => import('./components/Shop/Shop.js'))
const ProfileAddProduct = lazy(() => import('./components/ProfileAddProduct'))
const ProfileEditProduct = lazy(() => import('./components/ProfileEditProduct'))
const ProfileProducts = lazy(() => import('./components/ProfileProducts'))
const ProfileCategories = lazy(() => import('./components/ProfileCategories'))
const ProfileOrders = lazy(() => import('./components/ProfileOrders'))
const ProfileSettings = lazy(() => import('./components/ProfileSettings'))
const ProfileSettingsShop = lazy(() => import('./components/ProfileSettingsShop'))
const Product = lazy(() => import('./components/Product/Product.js'))
const ShopCart = lazy(() => import('./components/ShopCart'))
const Checkout = lazy(() => import('./components/Checkout/Checkout'))
const Help = lazy(() => import('./components/Help'))
const Siterules = lazy(() => import('./components/Siterules'))
const Order = lazy(() => import('./components/Order/Order.js'))
const ProfileDeleteAccount = lazy(() => import('./components/ProfileDeleteAccount'))
const Recover = lazy(() => import('./components/Recover'))
const Confirmation = lazy(() => import('./components/Confirmation'))







class App extends React.Component {
  constructor(props) {
    super(props)

    window.tempFetch = window.fetch
    window.fetch = (url, data) => {
      // console.log(' 1[HERE I AM] ')
      // console.log('this.props = ', this.props)
      // console.log('this.state = ', this.state)
      // console.log(' 2[HERE I AM] ')
      
      if (this.props.globals.login) {

        if (!this.interval) {
          this.interval = setInterval(() => {
            clearInterval(this.interval)
            this.interval = false
            // console.log('user logged')
            let { dispatch } = this.props
            dispatch(a.getNewNotifications())
          }, 1000)
        }
        
      }
      
      return window.tempFetch(url, data)
    }

    window.onerror = function(err) {
      console.log('###err = ', err)
      // if (err.toString() === 'Error: 401 Unauthorized') {
      //   // console.log('111this.props.err = ', this.props.err)
      //   const { dispatch } = this.props
      //   dispatch(a.logOutUser())
      // }
      return true
    }

    

    let enJson = {
      "en": {
        "plural-forms": "nplurals=2; plural=(n > 1);",
        "messages": {
          "Website development": [" "],
          "Web programming": [" "],
          "Copywriting": [" "],
          "canceledByBuyer": ["canceled by buyer"]
        }
      }
    }
    var catalogs = {
      "en": enJson["en"],
      "ru": enJson["en"],
      "ua": enJson["en"],
    }

    this.state = {
      // locale: this.props.globals.locale,
      catalogs
    }
  }

  componentDidMount() {
    // console.log('componentDidMount')
    // console.log('this.props = ', this.props)
    let params = new URLSearchParams(this.props.location.search)
    let getSsr = params.get('getSsr')
    // console.log('params = ', params)
    // console.log('params = ', params.get('getSsr'))
    // console.log('params = ', params.get('something'))
    // if (getSsr === null) {
    //   window.addEventListener('load', this.loadData)    
    // }

    // 2. include a json file
    if (this.props.globals.locale !== 'en') {
      this.getTranslations(this.props.globals.locale)
    }
  }

  // loadData = () => {
  //   // 1. include a recaptcha API
  //   let head = document.getElementsByTagName('head')[0]
  //   let script = document.createElement('script')
  //   script.setAttribute('data-main', 'js/main');
  //   script.src = `https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit&hl=${f.getReCaptchaLocale(this.props.globals.locale)}`
  //   script.type = "text/javascript"
  //   script.async = true 
  //   script.defer = true
  //   script.id = 'my-script'

  //   // console.log('script = ', script)
  //   head.appendChild(script)
  // }

  // componentDidUpdate(prevProps, prevState) {
  //   // console.log('componentDidUpdate')
  // }

  componentWillUnmount() {
    // remove a recaptcha API
    f.deleteReCaptcha()
  }

  getTranslations = (locale) => {
    // console.log('locale = ', locale)
    fetch(`/locale/${locale}.json`, {
      method: 'GET',
      headers: {
        // 'Content-Type': 'application/json',
        // 'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(r => {
        this.isFetching = false
        if (!r.ok) {
          return Promise.reject()
        } else {
          return r.json()
        }
      })
      .then(json => {
        // console.log('1#### json = ', json)
        if(json.error) {
          return this.setState({ ...json })
        } else {
          this.setState({
            locale,
            catalogs: json
          })
        }
      })
      .catch(error => {
        console.log(console.log('error = ', error));
        return this.setState({ error })
      })
  }

  render() {

    // return (
    //   <div>
    //     <div>
    //       <Header />
    //       <div className="Home">
    //         <div>
              
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // )

    return (
      <StoneProvider
        catalogs={this.state.catalogs}
        locale={this.props.globals.locale}
        className="two"
      >
        
        <Suspense fallback={
          <div>
            <Header />
            <div className="Home">
              <div>
                
              </div>
            </div>
          </div>
        }>
          
          <Switch className="one">
            <Route path={"/"} exact render={propsRouter => 
              <Home {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/"} exact render={propsRouter => 
              <Home {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/login"} exact render={propsRouter => 
              <Login {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/registration"} exact render={propsRouter => 
              <Registration {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/shop/:login"} exact render={propsRouter => 
              <Shop {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/profile/:login/add-product"} exact render={propsRouter => 
              <ProfileAddProduct {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/profile/:login/edit-product/:productId/"} exact render={propsRouter => 
              <ProfileEditProduct {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/profile/:login/products"} exact render={propsRouter => 
              <ProfileProducts {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/profile/:login/categories"} exact render={propsRouter => 
              <ProfileCategories {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/profile/:login/orders"} exact render={propsRouter => 
              <ProfileOrders {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/profile/:login/settings"} exact render={propsRouter => 
              <ProfileSettings {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/profile/:login/settings-shop"} exact render={propsRouter => 
              <ProfileSettingsShop {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/product/:productId"} exact render={propsRouter => 
              <Product {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/shopcart/"} exact render={propsRouter => 
              <ShopCart {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/checkout/"} exact render={propsRouter => 
              <Checkout {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/help/"} exact render={propsRouter => 
              <Help {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/siterules/"} exact render={propsRouter => 
              <Siterules {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/order/:orderToken/"} exact render={propsRouter => 
              <Order {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/profile/:login/delete-account/"} exact render={propsRouter => 
              <ProfileDeleteAccount {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/confirmation/"} exact render={propsRouter => 
              <Confirmation {...propsRouter} {...this.props} />
            } />
            <Route path={"/" + this.props.globals.locale + "/recover/"} exact render={propsRouter => 
              <Recover {...propsRouter} {...this.props} />
            } />
            <Route path='*' render={propsRouter =>
              <NoFound {...propsRouter} {...this.props} />
            } />
          </Switch>
              
        </Suspense>

      </StoneProvider>
    )
  }
}



const mapStateToProps = state => {
  // const { expiresIn, token } = state.globals
  // return { expiresIn, token }
  const { globals } = state
  return { globals }
}

App = connect(mapStateToProps)(App)
App = withRouter(App)



export default App
