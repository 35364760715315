import Cookie from 'js-cookie'
import validator from 'validator'
import cloneDeep from 'lodash/cloneDeep'

import Config from '../../Config.json'

// // 1. locale initialization
// let locale = window.localStorage.getItem('locale')
// if (!locale) {
//   window.localStorage.setItem('locale', 'en')
//   locale = 'en'
// }

// console.log('Config = ', Config)

let locales = {
  "en": true,
  "ru": true,
  "ua": true,
}



let countries = {
  "Russia": true,
  "Ukraine": true
}
let params = new URLSearchParams(window.location.search)
let country = params.get('country')
if (!countries[country]) {
  country = window.localStorage.getItem('country')
  if (!countries[country]) {
    country = 'all'
  }
} else {
  window.localStorage.setItem('country', country)
}



// #############################
// 1. locale initialization
// #############################
let locale = window.location.pathname.substr(1,2)
// if (!locale || (locale !== 'en' && locale !== 'ru' && locale !== 'ua' && locale !== 'in' && locale !== 'cn' && locale !== 'es' && locale !== 'de' && locale !== 'pl' && locale !== 'fr')) {
if (!locales[locale]) {
  // console.log('not exist')
  // 1.2 get from the localStorage
  locale = window.localStorage.getItem('locale')
  if (!locale) {
    window.localStorage.setItem('locale', Config.defaultLocal)
    locale = Config.defaultLocal
    Cookie.set('locale', Config.defaultLocal, { expires: 1000 })
  }
// } else if (locale === 'en' || locale === 'ru' || locale === 'ua' || locale === 'in' || locale === 'cn' || locale === 'es' || locale === 'de' || locale === 'pl' || locale === 'fr') {
} else {
  // console.log('exist')
  window.localStorage.setItem('locale', locale)
  Cookie.set('locale', locale, { expires: 1000 })
}
// console.log('locale = ', locale)
// if (window.location.pathname === '/') {
//   console.log('redirect')
//   window.location.href = `/${locale}/`
// }

// console.log('locale = ', locale)

// 2. user initialization
let id = ''
let login = ''
let avatar = ''
let role = ''
let cart = {}
let numberOfNewNotifications = 0

id = window.localStorage.getItem('id') || ''
// console.log('id = ', id)
login = window.localStorage.getItem('login') || ''
// console.log('login = ', login)
avatar = window.localStorage.getItem('avatar') || ''
role = window.localStorage.getItem('role') || ''
cart = window.localStorage.getItem('cart') || {}
if (validator.isJSON(cart + '')) {
  cart = JSON.parse(cart)
} else {
  cart = {}
}
// country = window.localStorage.getItem('country') || 'all'


if (avatar === 'null') {
  avatar = null
}



// dummy data
// login = 'neo'



const initialState = {
  id,
  login,
  role,
  locale,
  // expiresIn: expiresIn,
  rememberMe: false,
  avatar,
  cart,
  numberOfNewNotifications,
  country
}

// console.log('initialState = ', initialState)


export default (state = initialState, action) => {
  switch (action.type) {
    // case 'SET_LOCALE': {
    //   const { locale } = action.payload
    //   return {
    //     ...state,
    //     locale
    //   }
    // }
    case 'LOG_USER': {
      const obj = action.payload
      // let { expiresIn } = action.payload
      // expiresIn = +new Date + expiresIn * 1000
      return {
        ...state,
        ...obj,
        // expiresIn
      }
    }
    case 'LOG_OUT_USER': {
      return {
        ...state,
        id: '',
        login: '',
        avatar: '',
        role: ''
      }
    }
    case 'ADD_TO_CART': {
      const obj = action.payload
      // console.log('obj = ', obj)
      return {
        ...state,
        ...obj
      }
    }
    case 'SET_COUNTRY': {
      const { country } = action.payload
      return {
        ...state,
        country
      }
    }
    case 'SET_LOGIN': {
      const { login } = action.payload
      return {
        ...state,
        login
      }
    }
    case 'SET_NUMBER_OF_NEW_NOTIFICATIONS': {
      const { numberOfNewNotifications } = action.payload
      return {
        ...state,
        numberOfNewNotifications
      }
    }







    case 'RECEIVE_TOKEN': {
      let { token, expiresIn } = action.payload
      expiresIn = +new Date() + expiresIn * 1000
      return {
        ...state,
        token,
        expiresIn
      }
    }
    case 'UPDATE_LOGIN': {
      let { login } = action.payload
      return {
        ...state,
        login
      }
    }
    case 'UPDATE_AVATAR': {
      let { avatar } = action.payload
      return {
        ...state,
        avatar
      }
    }
    case 'SET_MESSAGE_REDIRECT': {
      let { messageRedirectId } = action.payload
      return {
        ...state,
        messageRedirect: { messageRedirectId }
      }
    }
    case 'REMOVE_MESSAGE_REDIRECT': {
      return {
        ...state,
        messageRedirect: {}
      }
    }
    case 'SET_PROJECT_FORM': {
      let { title, description, specializations, budget, deadline, language } = action.payload
      return {
        ...state,
        projectForm: { title, description, specializations, budget, deadline, language }
      }
    }
    case 'REMOVE_PROJECT_FORM': {
      return {
        ...state,
        projectForm: {}
      }
    }
    case 'SET_OFFER_FORM': {
      let { toId, description, budget, deadline, projectId } = action.payload
      return {
        ...state,
        offerForm: { toId, description, budget, deadline, projectId }
      }
    }
    case 'REMOVE_OFFER_FORM': {
      return {
        ...state,
        offerForm: {}
      }
    }
    case 'SET_ORDER_FORM': {
      let { title, description, budget, deadline, freelancerId } = action.payload
      return {
        ...state,
        orderForm: { title, description, budget, deadline, freelancerId }
      }
    }
    case 'REMOVE_ORDER_FORM': {
      return {
        ...state,
        orderForm: {}
      }
    }
    case 'CHANGE_VIEW_EDIT_PROFILE': {
      let { viewEditProfile } = action.payload
      return {
        ...state,
        viewEditProfile
      }
    }

    default: return state
  }
}